import React from 'react'
import ProductsPage from '../layout/ProductsPage'

const Vycepy = ({ vycepy, kegSizesById, allManufacturers }) =>  (
    <ProductsPage 
        products={vycepy}
        kegSizesById={kegSizesById}
        manufacturers={allManufacturers?.[353]}
        customCurrencyFormatForProdsWithKegSize={'Kč/den'}
        topNavBarLinkPath={'vycepy'}
        key={'vycepy'}
        isVycepy={true}
    />
)
export default Vycepy
