import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { webIsKegs, webIsOnemenu } from './../AppConfig';

const Sidebar = ({ setActiveMenuLabel }) => {
  return (
    <section className="drawer-list">
      <ul>
        {webIsKegs && (
          <>
            <li>
              <Link to="/">
                <a onClick={() => setActiveMenuLabel('Sudy')}>Sudy</a>
              </Link>
            </li>
            <li>
              <Link to="/vycepy">
                <a onClick={() => setActiveMenuLabel('Výčepy')}>
                  Výčepy a příslušenství
                </a>
              </Link>
            </li>
            <li>
              <Link to="/prodejna">
                <a onClick={() => setActiveMenuLabel('Prodejna')}>Prodejna</a>
              </Link>
            </li>
            <li>
              <Link to="/navody">
                <a onClick={() => setActiveMenuLabel('Návody')}>Návody</a>
              </Link>
            </li>
          </>
        )}
        {webIsOnemenu && (
          <>
            <li>
              <a href="#" onClick={() => setActiveMenuLabel('Piva')}>
                Piva
              </a>
            </li>
          </>
        )}
      </ul>
    </section>
  );
};

export default Sidebar;
