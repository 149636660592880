import * as actionTypes from './app.actionTypes'
import { AirshopApi } from '../CraftApi.axios'
import _ from 'lodash'

const generalFetchRequest = (dispatch, type) => {
    dispatch({ type })
}

const handleErrorInResponse = res => {
    throw new Error(res.messages && res.messages.length ? res.messages[0] : res.status);
}

const getActionTypes = type => {
    switch (type) {
        case 'kegs':
            return {
                req: actionTypes.KEGS_LIST_REQUEST,
                res: actionTypes.KEGS_LIST_RESPONSE,
                error: actionTypes.KEGS_LIST_FAILURE,
            };
        case 'vycepy':
            return {
                req: actionTypes.VYCEPY_LIST_REQUEST,
                res: actionTypes.VYCEPY_LIST_RESPONSE,
                error: actionTypes.VYCEPY_LIST_FAILURE,
            };
        default: 
            return {}
    }
}

export const fetchProducts = async (dispatch, manufacturers, categoryId, type, disabled=0, onOrder='ALL') => {
    const actionTypes = getActionTypes(type)
    generalFetchRequest(dispatch, actionTypes.req);

    const reqs = manufacturers.map(item => AirshopApi
        .get('fc/targetCommodity/findAllByTargetManufacturerCategory',
            { params: { 
                targetId: 4,
                order: 'date_created',
                sort: 'DESC',
                disabled,
                onOrder, 
                manufacturerId: item.id,
                limit: 100,
                categoryId
            } }
        ));

    await Promise.all(reqs)
        .then((responses) => {
            const productsByManufacturerId = {};
            responses.forEach(res => {
                if (res.data.status === 'OK') {
                    const items = res.data.items;
                    if (items.length) {
                        const catId = res.config.params.manufacturerId;
                        productsByManufacturerId[catId] = _(items)
                        .filter(item => item.manufacturer && item.manufacturerId && item.priceAmountSaleRetail )
                        .sortBy(['priceAmountSaleRetail'], ['ASC'])
                        .value();
                    }

                } else if (res.data.status === 'ERROR') {
                    handleErrorInResponse(res);
                }
            })
            dispatch({
                type: actionTypes.res,
                payload: productsByManufacturerId,
            })
        })
        .catch(err => {
            dispatch({
                type: actionTypes.error,
                payload: err,
            })
        })
}

export const fetchBeers = async dispatch => {
    generalFetchRequest(dispatch, actionTypes.BEERS_LIST_REQUEST);

    await AirshopApi
        .get('fc/targetCommodity/findAllByTarget',
            { params: { 
                targetId: 2,
                order: 'date_created',
                sort: 'DESC',
                disabled: 0,
            } }
        )
        .then(({ data: res }) => {
            if (res.status === 'OK') {
                dispatch({
                    type: actionTypes.BEERS_LIST_RESPONSE,
                    payload: _.sortBy(res.items, ['ord']),
                })
            } else if (res.status === 'ERROR') {
                handleErrorInResponse(res);
            }
        })
        .catch(err => {
            dispatch({
                type: actionTypes.KEGS_LIST_FAILURE,
                payload: err,
            })
        })
}

export const fetcKegSizesList = async dispatch => {
    generalFetchRequest(dispatch, actionTypes.COMMODITY_KEG_SIZES_LIST_REQUEST);

    await AirshopApi
        .get('fc/commodityKegSize')
        .then(({ data: res }) => {
            if (res.status === 'OK') {
                const kegSizesById = {};
                res.items.length && res.items.forEach(item => {
                    kegSizesById[item.id] = item;
                });
                dispatch({
                    type: actionTypes.COMMODITY_KEG_SIZES_LIST_RESPONSE,
                    payload: kegSizesById,
                })
            } else if (res.status === 'ERROR') {
                handleErrorInResponse(res);
            }
        })
        .catch(err => {
            dispatch({
                type: actionTypes.COMMODITY_KEG_SIZES_LIST_FAILURE,
                payload: err,
            })
        })
}

export const fetchManufacturers = async (dispatch, categoryId, disabled=0, onOrder='ALL') => {
    generalFetchRequest(dispatch, actionTypes.MANUFACTURERS_REQUEST);

    await AirshopApi
        .get('fc/manufacturer/filter', {
            params: { 
                targetId: 4, categoryId, limit: 500, order: 'name', sort: 'ASC', disabled, onOrder
            }
        })
        .then(({ data: res }) => {
            if (res.status === 'OK') {
                dispatch({
                    type: actionTypes.MANUFACTURERS_RESPONSE,
                    payload: {
                        [categoryId]: res.items.filter(item => item.cnt)
                    },
                })
            } else if (res.status === 'ERROR') {
                handleErrorInResponse(res);
            }
        })
        .catch(err => {
            dispatch({
                type: actionTypes.MANUFACTURERS_FAILURE,
                payload: err,
            })
        })
}
