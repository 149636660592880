import React from 'react'

const Instructions = () => {
    return (
        <div style={{ padding: '3.5rem 2rem'}}>
            <h2 style={{color: '#f87227', paddingBottom: '1.5rem'}}>Informace k výčepům a soudkům</h2>
            <p>
            V Beershopu nabízíme kromě sudů s pivem i výčepních zařízení. Při jejich výběru záleží na tom, jak velký sud nebo soudek hodláte koupit a vypít. Velikost necháme na vás, protože záleží na počtu osob, které chcete pozvat a jejich apetitu. To musíte odhadnout sami. Pokud se bude jednat o střední párty, doporučujeme pořídit 30 l sud piva a k němu jednokohoutové výčepní zařízení Lindr 25/K s kompresorem a chlazením o max. výkonu 35 l/h. Chcete-li čepovat dva druhy piva, nebo máte větší skupinu lidí, doporučujeme pořídit dva 30 l sudy a dvoukohoutové výčepní zařízení Lindr 40/K, které má také větší výkon 50 l/h.
            </p>
            <p>
            K výčepním zařízením vám dodáme hadičky a naražeče pro připojení sudů a samožřejmě vám vše na prodejně vysvětlíme. Naražeče jsou narážecí hlavice a slouží k propojení výčepního zařízení a sudu. Na sud se nasunou nebo našroubují a pákou směrem dolů se sud narazí. Není třeba se tohoto úkonu vůbec bát, vše je jednoduché a robustní. Obě hadičky se připojí k výčepnímu zařízení. Modrá vhání do sudu stlačený vzduch, který generuje kompresor výčepu. Tlak vzduchu vytláčí pivo ze sudu druhou průhlednou hadičkou do výčepu, kde se vychladí a kohoutem na druhé straně čepuje. Průhlednou hadičkou uvidíte pivo produdit ze sudu. Občas na začátku proudí jenom bílá pěna. Toho se nelekejte, je potřeba jenom počkat, až se pivo uklidní. Nechte sud v klidu.
            </p>
            <p>
            Pokud celý sud nevypijete, můžete jej odrazit a znovu narazit a dopít třeba druhý den. Moc s tím ale nedoporučujeme otálet, protože kompresor do sudu vhání normální vzduch a ten pivu nedělá dobře. Lepší je tedy vytláčet pivo ze sudu stlačeným CO2, na což byste ale potřebovali větší bombu a ventily. Zkušenější pivaři vám to jistě vysvětlí lépe než my zde.
            </p>
            <p>
            Pokud máte menší párty, nechcete tahat velký sud nebo nemáte v přírodě přístup k elektřině, doporučujeme pořídit pivo v našich menších 5 l soudcích. Ty jsou nerezové, dvouplášťové a pivo v nich vydrží vychlazené cca. 20 hodin. My vám soudek s pivem předáme vychlazený na 6-8 °C, takže doporučujeme jej před konzumací alespoň 24 hodin skladovat v lednici, aby zůstal vychlazený. Výhoda menších soudků je také v tom, že můžete servírovat více druhů piva najednou.
            </p>
            <p>
            Pivo z těchto soudků se čepuje pomocí jednoduché výčepní sady Beerkeg, kterou vám k soudku zapůjčíme. Pivo se pak ze soudku vytláčí pomocí CO2 bombičky, kterou našroubujete na regulátor tlaku. Jedna bombička vydrží na vyčepování 5 l soudku. Bombičky jsou jiné než ty šlehačkové, protože mají závit. Prodáváme je v balení po 3 ks.
            </p>
            <p>
            Proces naražení malého soudku je stejný, jako u velkého sudu, což jsme už popsali výše. Výčepní sadu od nás obdržíte zkompletovanou a po naražení stačí jenom našroubovat bombičku a můžete čepovat pivo. Není problém, když nevyčepujete celý sud. Uzavřete regulátor CO2, odražte naražeč a dejte sud do lednice. Pivo bude ještě nějakou dobu konzervováno pomocí CO2, takže vydrží i několik dní. Tedy pokud v soudku piva nebude na dně a pokud zůstane v sudu tlak.
            </p>
            <p>
            Když si třeba pořídíte dva soudky se dvěma různými pivy, doporučujeme k nim zapůjčit také dvě sady výčepů. Ke každému můžete připojit samostatnou bombičku a nebo můžete připojit společnou větší bombičku Sodastream, kterou máte doma nebo ji u nás můžete také koupit. Sodastream má ale jiný závit, takže k připojení potřebujete adaptér. Spolu s adaptérem vám zapůjčíme i regulátor tlaku a sestavu hadiček pro rozvedení plynu k více soudkům.
            </p>
            <p>
            Po úspěšném absolvování párty nám vraťte kompletní výčepní zařízení i včetně příslušenství. Stačí jenom opláchnout odkapní misku a otřít výčepní kohout. Hadičky nemusíte proplachovat. Postaráme se o jeho sanitaci a kontrolu pro další použití.
            </p>
            <p>
            <a href="/assets/pdf/Navod_vycepy_Lindr.pdf" target="_blank" style={{fontWeight: 'normal'}}>Zde najdete návod pro obsluhu výčepních zařízení Lindr.</a>
            </p>
            <p>
            Na zdraví!
            </p>
        </div>
    )
}

export default Instructions