import React, { useState, useEffect, useRef, createRef } from 'react'
import TopNavBar from '../layout/TopNavBar'
import Product from '../components/Product'
import KegProduct from '../components/KegProduct'
import { webIsKegs, webIsOnemenu } from '../AppConfig'
import _ from 'lodash'

const ProductsPage = ({ products, kegSizesById, manufacturers, customCurrencyFormatForProdsWithKegSize='Kč', topNavBarLinkPath='', inStockCheckbox, setInStockCheckbox, isVycepy=false }) => {
    const [productViewed, setProductViewed] = useState()
    const [beerManufacturers, setBeerManufacturers] = useState([])
    const [activeTab, setActiveTab] = useState(0)
    const titlesRefs = useRef([])
    const navTabsRef = useRef({
        navBar: useRef(),
        navBarItems: [],
    });
    const tabClickEvent = useRef(false)
    const clickedTabId = useRef(null)

    // const menuItems = ['Piva', 'Sudy', 'Výčepní zařízení'];

    useEffect(() => {
        let isMounted = true;               // note mutable flag
        if (manufacturers && manufacturers.length && products && Object.keys(products) && Object.keys(products).length) {
                const mans = _(manufacturers).filter(man => products[man.id]).value();
                if (!titlesRefs.current.length) {
                    mans.forEach(() => {
                        titlesRefs.current.push(createRef());
                        navTabsRef.current.navBarItems.push(createRef())
                    })
                }
                isMounted && window.addEventListener('scroll', onTitleScroll)
                isMounted && setBeerManufacturers(mans)
        }
        return () => { isMounted = false };
    }, [manufacturers, products])

    // When scrolling, active tabs is changing based on the section in the viewport
    // It ignores this function when clicking on the tab and auto-scrolling through the sections to get to the selected section
    const onTitleScroll = (e) => {
      const heights = titlesRefs.current.map(
        item => {
            if (item?.current?.getBoundingClientRect()) {
                return Math.abs(item.current.getBoundingClientRect().y)
            } else {
                return 0
            }
        }
      );
      const isAnyHightClose = heights.some(hight => hight < 50);
      const indexOfMinAbsHight = isAnyHightClose 
            ? heights.indexOf(Math.min(...heights)) 
            : null;

      // Scrolling with auto set of active tab
      if (!tabClickEvent.current && isAnyHightClose) {
        setActiveTab(indexOfMinAbsHight);
        onNavBarScroll(indexOfMinAbsHight)
      // User clicked and it scrolls to the selected section
      } else if (tabClickEvent.current && isAnyHightClose) {
        if (indexOfMinAbsHight === clickedTabId.current) {
          tabClickEvent.current = false;
          clickedTabId.current = null;
        }
      }
    }

    const onNavBarScroll = (activeTab) => {
        const activeElOffset = navTabsRef.current.navBarItems[activeTab].current?.offsetLeft
        navTabsRef.current.navBar.current?.scrollTo({
            left: activeTab > 0 ? activeElOffset - 10 : 0,
            top: navTabsRef.current.navBar.current?.scrollTop,
            behavior: 'smooth',
        });        
    }

    const onProductClicked = productId => {
        setProductViewed(productViewed === productId ? null : productId)
    }

    const setTabAsActive = (i) => {
      tabClickEvent.current = true
      clickedTabId.current = i
      setActiveTab(i)
    }

    return (
        <>
            {beerManufacturers && products && Object.keys(products) && Object.keys(products).length ? (
                <TopNavBar 
                    tabs={beerManufacturers} 
                    active={activeTab} 
                    setActive={setTabAsActive} 
                    inStockCheckbox={inStockCheckbox}
                    setInStockCheckbox={setInStockCheckbox}
                    ref={navTabsRef} 
                    topNavBarLinkPath={topNavBarLinkPath}
                />
            ) : null}
            <div className="shadow"></div>
            <div className="cover"></div>
            <div id="content-container">
                {beerManufacturers.length && products && Object.keys(products) && Object.keys(products).length 
                ? beerManufacturers.map((man, i) => (
                    <div id={i} key={i}>
                        <div style={i === 0 ? {marginTop: '-120px'} : {}} ref={titlesRefs.current[i]}>
                            <h2 className="category-name" id={man.id}>{man.name}</h2>
                            <div>
                            {products[man.id]?.length && products[man.id].map((product, i) => {
                                if (webIsKegs) {
                                    return (
                                        <KegProduct 
                                            product={product} 
                                            key={`${i}-${product.id}`} 
                                            kegSize={
                                                kegSizesById[product.commodityKegSizeId] 
                                                ? kegSizesById[product.commodityKegSizeId] 
                                                : null
                                            } 
                                            productViewed={productViewed}
                                            isProductViewed={productViewed === product.id}
                                            onClick={() => onProductClicked(product.id)}
                                            customCurrencyFormatForProdsWithKegSize={customCurrencyFormatForProdsWithKegSize}
                                            isVycepy={isVycepy}
                                            isPrislusenstvi={isVycepy && man?.id === 356}
                                        />
                                    )
                                } else {
                                    return (
                                        <Product 
                                            product={product} 
                                            key={i} 
                                            kegSize={
                                                kegSizesById[product.commodityKegSizeId] 
                                                ? kegSizesById[product.commodityKegSizeId] 
                                                : null
                                            } 
                                        />
                                    )
                                }
                            })}
                            </div>
                        </div>
                    </div>
                ))
                : (
                <div style={{marginTop: 50, paddingLeft: 10, textAlign: 'center', opacity: 0.5}}>
                    <div className="spinner" />
                </div>
                )}
            </div>
        </>
    )
}

export default ProductsPage
