import React from 'react'
import {
    Switch,
    Route,
  } from 'react-router-dom'
import Kegs from '../pages/Kegs'
import Shop from '../pages/Shop'
import Vycepy from '../pages/Vycepy'
import Instructions from '../pages/Instructions'

const Content = (props) => {

    const desktopText = (
        <div className="desktop-text">
            Sudy a výčepní zařízení nabízíme nově jako doplňkový sortiment v naší pražské prodejně na Palmovce. Zde je seznam sudového piva, které u nás můžete zakoupit. Zároveň tato pobočka funguje jako půjčovna výčepních zařízení.
        </div>
    )

    return (
        <div className="container">
            <main>
                <Switch>
                    <Route path="/prodejna" exact={true}>                 
                        <Shop />
                    </Route>
                    <Route path="/vycepy" exact={true}> 
                        { desktopText }
                        <Vycepy {...props} />
                    </Route>
                    <Route path="/navody" exact={true}> 
                        <Instructions {...props} />
                    </Route>
                    <Route path="/" exact={true}> 
                        { desktopText}
                        <Kegs {...props} />
                    </Route>
                </Switch>        
            </main>
        </div>
    )
}

export default Content
