import React, { useContext, useReducer } from 'react';
import AppContext from './app.context'
import AppReducer from './app.reducer'

export const useAppState = () => {
  const { state, dispatch } = useContext(AppContext);
  return [state, dispatch];
};

export const AppStateProvider = ({ children }) => {
  const initialState = {
      kegs: null,
      vycepy: null,
      beers: null,
      kegSizesById: null,
      manufacturers: null,
      error: null, 
      loading: false,
  };

  const [state, dispatch] = useReducer(AppReducer, initialState);

  return (
    <AppContext.Provider
      value={{
        state: state,
        dispatch: dispatch
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
