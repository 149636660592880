import React from 'react'
import ProductsPage from '../layout/ProductsPage'

const Kegs = ({ kegs, kegSizesById, allManufacturers, inStockCheckbox, setInStockCheckbox }) =>  (
    <ProductsPage 
        products={kegs}
        kegSizesById={kegSizesById}
        manufacturers={allManufacturers?.[2]}
        inStockCheckbox={inStockCheckbox}
        setInStockCheckbox={setInStockCheckbox}
        key={'kegs'}
    />
)
export default Kegs
