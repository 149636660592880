import React, { forwardRef } from 'react';

const TopNavBar = forwardRef(({ tabs, active, setActive, topNavBarLinkPath, inStockCheckbox, setInStockCheckbox }, ref) => {
    return (
        <nav className="items-center row no-wrap text-grey" role="tablist">
            <div className="nav-tabs hide-scrollbar items-center no-wrap row" ref={ref.current.navBar}>
                
                {tabs.map((tab, i) => (
                    <div tabIndex={i === active ? -1 : 0}  key={i}
                        className={`nav-tab flex flex-center text-center btn non-selectable focusable hoverable ${i === active && 'active'}`}
                        onClick={() => setActive(i)}
                        ref={ref.current.navBarItems[i]}
                    >
                        <div tabIndex={i === active ? -1 : 0} className="focus-helper">
                        </div>
                        
                        <a href={`/${topNavBarLinkPath}#${tab.id}`}>
                            <div className="nav-tab-content flex-center non-selectable row no-wrap">
                                <div className="nav-tab-label">{tab.name}</div>
                            </div>
                        </a>
                    </div>
                ))}
                    <div 
                        className={`nav-tab flex flex-center text-center btn non-selectable focusable hoverable check`}
                    >
                        <div className="focus-helper">
                        </div>
                        
                        <a className="hoverable">
                            <div className="nav-tab-content flex-center non-selectable row no-wrap checkbox" >
                                <input type="checkbox" value={true} name="instock-check" id="instock-check" className="checkbox" onChange={() => setInStockCheckbox(!inStockCheckbox)} style={{marginRight: 7}}/>
                                <label className="nav-tab-label" htmlFor="instock-check">Pouze skladem</label>
                            </div>
                        </a>
                    </div>
            </div>
        </nav>
    )
})

export default TopNavBar;;
