 import * as actionTypes from './app.actionTypes';
//  kegSizesById munufacturers
 const appReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.KEGS_LIST_REQUEST:
        case actionTypes.VYCEPY_LIST_REQUEST:
        case actionTypes.BEERS_LIST_REQUEST:
        case actionTypes.COMMODITY_KEG_SIZES_LIST_REQUEST:
        case actionTypes.MANUFACTURERS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case actionTypes.KEGS_LIST_RESPONSE:
            return {
                ...state,
                loading: false,
                kegs: action.payload
            };
        case actionTypes.VYCEPY_LIST_RESPONSE:
            return {
                ...state,
                loading: false,
                vycepy: action.payload
            };
        case actionTypes.BEERS_LIST_RESPONSE:
            return {
                ...state,
                loading: false,
                beers: action.payload
            };
        case actionTypes.COMMODITY_KEG_SIZES_LIST_RESPONSE:
            return {
                ...state,
                loading: false,
                kegSizesById: action.payload
            };
        case actionTypes.MANUFACTURERS_RESPONSE:
            return {
                ...state,
                loading: false,
                manufacturers: {
                    ...state.manufacturers,
                    ...action.payload
                }
            };
        case actionTypes.KEGS_LIST_FAILURE:
        case actionTypes.VYCEPY_LIST_FAILURE:
        case actionTypes.BEERS_LIST_FAILURE:
        case actionTypes.COMMODITY_KEG_SIZES_LIST_FAILURE:
        case actionTypes.MANUFACTURERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
  };

export default appReducer
  