import React, { forwardRef } from 'react'

const MenuIcon = forwardRef(({activeText}, ref) => {
    return (
        <>
            <input id="hamburger" className="hamburger" type="checkbox" ref={ref} />
            <label className="hamburger" htmlFor="hamburger">
                <i></i>
                <text>
                <close>Zavřít</close>
                {/* <open>{activeText}</open> */}
                </text>
            </label>
        </>

    )
})

export default MenuIcon