export const KEGS_LIST_REQUEST = 'KEGS_LIST_REQUEST'
export const KEGS_LIST_RESPONSE = 'KEGS_LIST_RESPONSE'
export const KEGS_LIST_FAILURE = 'KEGS_LIST_FAILURE'

export const VYCEPY_LIST_REQUEST = 'VYCEPY_LIST_REQUEST'
export const VYCEPY_LIST_RESPONSE = 'VYCEPY_LIST_RESPONSE'
export const VYCEPY_LIST_FAILURE = 'VYCEPY_LIST_FAILURE'

export const BEERS_LIST_REQUEST = 'BEERS_LIST_REQUEST'
export const BEERS_LIST_RESPONSE = 'BEERS_LIST_RESPONSE'
export const BEERS_LIST_FAILURE = 'BEERS_LIST_FAILURE'

export const COMMODITY_KEG_SIZES_LIST_REQUEST = 'COMMODITY_KEG_SIZES_LIST_REQUEST'
export const COMMODITY_KEG_SIZES_LIST_RESPONSE = 'COMMODITY_KEG_SIZES_LIST_RESPONSE'
export const COMMODITY_KEG_SIZES_LIST_FAILURE = 'COMMODITY_KEG_SIZES_LIST_FAILURE'

export const MANUFACTURERS_REQUEST = 'MANUFACTURERS_REQUEST'
export const MANUFACTURERS_RESPONSE = 'MANUFACTURERS_RESPONSE'
export const MANUFACTURERS_FAILURE = 'MANUFACTURERS_FAILURE'