import React, { useState, useEffect } from 'react'
import ModalImage from 'react-modal-image'
import { motion } from 'framer-motion'
import moment from 'moment'

const KegProduct = ({ product, kegSize, isProductViewed, onClick, productViewed, customCurrencyFormatForProdsWithKegSize, isVycepy, isPrislusenstvi=false }) => {
  const [expanded, setExpanded] = useState(false);
  const [mainPriceProp, setMainPriceProp] = useState(!isVycepy ? 'priceAmountSaleRetail' : 'priceAmountPurchaseRetail') // jira BCM-85, Roman comment 03/Mar/23 11:08 AM
  const [img, setImg] = useState(product.path ? product.path : kegSize && kegSize.path ? kegSize.path : null)

  const expandableSetting = {
    hidden: { opacity: 0, height: 0 },
    show: {
      opacity: 1,
      height: 'auto',
      transition: {
        staggerChildren: 0.5,
          type: "spring",
          damping: 10,
          mass: 0.75,
          stiffness: 100,
      }
    }
  }

  const descriptionSetings = {
    ...expandableSetting,
    hidden: { opacity: 1, height: 0}
  }
  useEffect(() => {
      if (expanded && !isProductViewed) {
          setExpanded(false);
      }
  }, [isProductViewed]);

  return (
      <div className={expanded && 'expanded'} onClick={() =>{ onClick(); setExpanded(!expanded)}}>
          <div className="product-container row">
              <div>

                  <div role="img" className="product-photo" onClick={e => e.stopPropagation()}>
                      <div style={{paddingBottom: '100%'}}></div>
                      <ModalImage
                          small={`https://img.beershop.cz${img}`}
                          large={`https://img.beershop.cz${img}`}
                          className="photo"
                          hideDownload={true}
                          hideZoom={true}
                          alt=""
                      />
                      {/* <div className="photo" style={{
                          backgroundImage: `url("https://img.beershop.cz${img}")`,
                      }}></div> */}

                  </div>
              </div>
              <div className="info col">
                  <h6 className="product-title">
                      {product.name}
                  </h6>
                  { kegSize && (
                      <p className="product-style">
                          { product.alternativeName} {product.abraAlkohol ? (product.abraPlato + '°, ') : ' '}{kegSize.name}
                      </p>
                  )}

                  <div className="items-center justify-between wrap row">
                      <p className="product-price">
                          {product[mainPriceProp] ? product[mainPriceProp] : '--'} {' ' + (
                            kegSize ? customCurrencyFormatForProdsWithKegSize : 'Kč'
                          ) + ' '} { product.onOrder ? (
                            <span style={{paddingLeft: '7px', color: '#f87227'}}>Na objednávku</span>
                          ) : (
                            <span style={{paddingLeft: '7px', color: '#28a745'}}>Skladem</span>
                          )}
                      </p>
                  </div>

                  <motion.p 
                    variants={descriptionSetings}
                    className="product-annotation">
                      {product.annotation && (isProductViewed && expanded ? product.annotation : product.annotation.substr(0, 40) + '...')}
                  </motion.p>

                  <motion.div
                    variants={expandableSetting}
                    animate={expanded ? "show" : "hidden"}
                    className="product-container row" onClick={() => setExpanded(!expanded)}
                  >
                    <div className="expanded-info">
                        <div className="items-center row">
                            { kegSize && (//customCurrencyFormatForProdsWithKegSize
                              <p className="product-price col" style={{fontSize: '95%'}}>
                                  Záloha: {`${kegSize?.refundableDeposit} Kč`}
                              </p>
                            )}

                            { isVycepy && 
                              <p className="product-price col" style={{fontSize: '95%'}}>
                                  {`${product?.priceAmountSaleRetail} Kč/víkend`}
                              </p>
                            }
                            { product.onOrder ? (
                              <>
                                <p className="product-price col" style={{fontSize: '95%', color: '#f87227'}}>
                                  Na objednávku do: { moment(product.dateOrder).format('DD.MM.YYYY') }
                                </p>
                                <p className="product-price col" style={{fontSize: '95%', color: '#f87227'}}>
                                  K vyzvednutí: { moment(product.dateDelivery).format('DD.MM.YYYY') }
                                </p>
                              </>
                            ) : ''}
                            <p style={{padding: 0, marginTop: '5px', marginBottom: 0}} className="col">
                              Rezervujte na tel. čísle <a href="tel:607056512" onClick={(e) => {e.stopPropagation()}} className="orange-text">607056512</a>
                            </p>
                        </div>
                    </div>
                  </motion.div>
              </div>
          </div>
      </div>
  )
}

export default KegProduct
