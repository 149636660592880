import React from 'react'

const Shop = () => {
    return (
        <div style={{ padding: '3.5rem 2rem'}}>
            <h2 style={{color: '#f87227', paddingBottom: '1.5rem'}}>Prodejna</h2>
            <p>
                <strong>
                    Beershop. cz <br />
                    Sokolovská 188, Praha 8 - Palmovka <br />
                </strong>
                <a href="https://goo.gl/maps/4KrnmUusY512" target="_blank" style={{fontWeight: 'normal'}}>(odkaz na Google Maps)</a>

                
            </p>

            <p style={{fontWeight: 'normal'}}>
                <strong>Otevírací doba:</strong> <br />
                pondělí - pátek 10-19 h <br />
                sobota 10-16 h <br />
                Telefon na prodejnu je <strong>+420 607 056 512</strong> (v provozu pouze v otvíracích hodinách prodejny)
            </p>
        </div>
    )
}

export default Shop