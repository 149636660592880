// Local development only
export const environmentIsDev = process.env.NODE_ENV === 'development';
// Alfa branch (beta test data)
export const environmentIsProd = process.env.NODE_ENV === 'production';
// Master branch (real data), in prod:master, NODE_ENV is production as well
export const environmentIsProdMaster = process.env.REACT_APP_CUSTOM_ENV === 'productionMaster';

export const environmentIsDevMaster = process.env.REACT_APP_CUSTOM_ENV === 'devMaster';

// Build only given web parts but in development, show everything
export const webIsOnemenu = process.env.REACT_APP_WEB === 'menu.beershop.cz';
export const webIsKegs = process.env.REACT_APP_WEB === 'sudy.beershop.cz';

const devAirshopUrl = 'https://airshop.beershop.cz';
const prodAirshopUrl = 'https://airshop.beershop.cz';
const prodMasterAirshopUrl = 'https://airshop.beershop.cz';
const devMasterAirshopUrl = 'https://airshop.beershop.cz';

const devImgUrl = 'https://betaimg1.love.cz';
const prodImgUrl = 'https://img.love.cz';
const prodMasterImgUrl = 'https://img.love.cz';
const devMasterImgUrl = 'https://img.love.cz';

export const imgUrl = () => {
  if (environmentIsDev && !environmentIsProdMaster && !environmentIsDevMaster) {
    return devImgUrl;
  } else if (environmentIsProd
    && !environmentIsProdMaster
    && !environmentIsDevMaster) {
    return prodImgUrl;
  } else if (environmentIsProdMaster) {
    return prodMasterImgUrl;
  } else if (environmentIsDevMaster) {
    return devMasterImgUrl;
  }
}

export const airshopUrl = () => {
  if (environmentIsDev && !environmentIsProdMaster && !environmentIsDevMaster) {
    return devAirshopUrl;
  } else if (environmentIsProd
    && !environmentIsProdMaster
    && !environmentIsDevMaster) {
    return prodAirshopUrl;
  } else if (environmentIsProdMaster) {
    return prodMasterAirshopUrl;
  } else if (environmentIsDevMaster) {
    return devMasterAirshopUrl;
  }
}
