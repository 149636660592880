import React, { useState, useRef } from 'react'
import MenuIcon from '../components/MenuIcon'
import Sidebar from './Sidebar'
import { webIsKegs } from './../AppConfig'
import { Link } from 'react-router-dom'

const Header = () => {
    const [activeMenuText, setActiveMenuText] = useState(webIsKegs ? 'Sudy' : 'Piva');
    const menuIconRef = useRef();

    const setActiveMenuLabel = (text) => {
        setActiveMenuText(text);
        // Simulate icon click so the opened sidebar closes
        menuIconRef.current.click();
    }

    return (
        <header className="fixed-top">
            <div 
                id="toolbar" 
                className="row no-wrap items-center justify-between q-pt-md"
            >
                <Link to="/">
                    <img src="/assets/img/logo.png" loading="eager" className="logo" alt="" />
                </Link>

                <MenuIcon ref={menuIconRef} activeText={activeMenuText} />
                <Sidebar setActiveMenuLabel={setActiveMenuLabel} />
            </div>
        </header>
    )
}

export default Header