import './scss/style.scss';
import React, { useEffect, useState } from 'react'
import Header from './layout/Header'
import Content from './layout/Content'
import { webIsKegs, webIsOnemenu } from './AppConfig'
import { useAppState } from './contexts/app.state'
import { fetchProducts, fetchBeers, fetcKegSizesList, fetchManufacturers } from './contexts/app.actions'

function App() {
  const [appState, appDispatch] = useAppState();
  const [inStockCheckbox, setInStockCheckbox] = useState(false)
  const { kegs, vycepy, beers, kegSizesById, manufacturers } = appState;

  useEffect(() => {
    webIsKegs && fetcKegSizesList(appDispatch);
    webIsOnemenu && fetchBeers(appDispatch);
    fetchManufacturers(appDispatch, 353); // Výčepy category
    // fetchManufacturers(appDispatch, 2); //Kegs category, handled below
  }, [])

  useEffect(() => {
    const onOrder = inStockCheckbox ? 0 : 'ALL'
    fetchManufacturers(appDispatch, 2, 0, onOrder); //Kegs category
  }, [inStockCheckbox])


  useEffect(() => {
    if (manufacturers && manufacturers[2] /*&& !kegs*/) {
      fetchProducts(appDispatch, manufacturers[2], 2, 'kegs', 0, inStockCheckbox ? 0 : 'ALL')
    }

    if (manufacturers && manufacturers[353] && !vycepy) {
      fetchProducts(appDispatch, manufacturers[353], 353, 'vycepy')
    }
    // console.log(manufacturers)
  }, [manufacturers])

  return (
      <div id="layout">
        <Header />
        <Content 
          kegs={kegs} 
          vycepy={vycepy}
          beers={beers} 
          kegSizesById={kegSizesById} 
          allManufacturers={manufacturers}
          inStockCheckbox={inStockCheckbox}
          setInStockCheckbox={setInStockCheckbox}
        />
      </div>
  );
}

export default App;
